import React from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import { Slide, Fade, Zoom } from 'react-slideshow-image'

const styles = theme => ({
  mainSlider: {
    position: 'relative',
    height: '100vh',
    marginTop: theme.spacing(-16),
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
      marginTop: theme.spacing(-12),
    },
  },
  slide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    background: 'transparent none center center',
    backgroundSize: 'cover',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
    },
  },
})

export default withStyles(styles)(
  class Slider extends React.Component {
    render() {
      const { classes, content, lang, scale } = this.props
      // console.log(content)
      return (
        <Zoom scale={scale} duration={4250} transitionDuration={750} className={clsx(classes.mainSlider, 'mainSlider')}>
          {content.map((item, key) => {
            return (
              <div className={classes.slide} style={{ backgroundImage: `url(${item.image})` }} key={key}>
                <Typography variant="h1">{item.caption}</Typography>
              </div>
            )
          })}
        </Zoom>
      )
    }
  },
)
