import React from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { Container, Grid, Typography, Button } from '@material-ui/core'

const styles = theme => ({
  root: {
    padding: theme.spacing(12),
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(6, 6, 12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2, 10),
    },
    // backgroundColor: 'white',
    // color: 'white',
  },
  title: {
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
  },
  photoGallery: {
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    '& *': {
      transition: '0.25s ease-in-out all',
    },
  },
  filterPanel: {
    // display: 'flex',
    padding: theme.spacing(3, 3, 1),
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.085)',
    textAlign: 'center',
  },
  filterButton: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    marginBottom: 16,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  imageContainer: {
    margin: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    animation: 'fadeUP 0.5s',
    textAlign: 'center',
    borderRadius: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      display: 'block',
      width: '100%',
      opacity: 0.9,
      transition: '0.25s ease-in-out all',
      '&:hover': {
        opacity: 1,
      },
    },
    '& .imageContainerText': {
      position: 'absolute',
      // bottom: theme.spacing(2),
      // left: theme.spacing(2.5),
      right: theme.spacing(1),
      bottom: theme.spacing(0.75),
      left: theme.spacing(1),
      padding: theme.spacing(2),
      background: 'linear-gradient(0deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 100%)',
      fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'left',
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    '&:hover .imageContainerText': {
      fontSize: '108%',
      [theme.breakpoints.up('md')]: {
        fontSize: '128%',
      },
      cursor: 'pointer',
    },
  },
})

export default withStyles(styles)(
  class ProductGallery extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        displayedCategories: props.content,
        currentCategory: 'all',
        active: false,
      }
    }

    toggleActive = () => {
      this.setState({
        active: !this.state.active,
      })
    }

    selectCategory = element => {
      var categoryName = element.toLowerCase()
      var displayedCategories = this.props.content.filter(function(el) {
        if (el.productFeatured) {
          var searchValue = el.productFamily.toLowerCase()
          // console.log(searchValue.indexOf(categoryName) !== -1)
          return searchValue.indexOf(categoryName) !== -1
        }
        return null
      })

      this.setState({
        displayedCategories: displayedCategories,
        currentCategory: element,
      })
      console.log('filter is: ' + element, this.state)
    }
    resetFilter = element => {
      console.log('reset filter')
      this.setState({
        displayedCategories: this.props.content,
        currentCategory: 'all',
      })
    }

    render() {
      const { classes, props, content, lang } = this.props
      const { currentCategory } = this.state

      let buttonClass = this.state.active ? 'active' : ''
      let categoryToSelect = this.selectCategory
      let getUniqueCategories = []
      let getFeaturedProducts = []

      content.forEach(function(item) {
        if (item.productFeatured) {
          if (getUniqueCategories.indexOf(item.productFamily) === -1) getUniqueCategories.push(item.productFamily)
          getFeaturedProducts.push(item)
        }
      })

      // let history = useHistory()
      // const handleClick = (event, path) => {
      //   // console.log("clicked ", path);
      //   history.push('products/' + path)
      // }

      return (
        <div className={classes.root} style={this.props.style}>
          <div className={classes.filterPanel}>
            <Typography variant="h2" className={classes.title}>
              Recomandări
            </Typography>
            {/* <Button
              variant="outlined"
              color="primary"
              onClick={this.resetFilter}
              className={classes.filterButton}
              style={this.state.displayedCategories.length === content.length ? { backgroundColor: 'white' } : {}}>
              All
            </Button>
            {getUniqueCategories.map(function(item, i) {
              var boundClick = categoryToSelect.bind(null, item)
              // console.log(item)

              return (
                <Button
                  key={i}
                  variant="outlined"
                  color="primary"
                  onClick={boundClick}
                  className={classes.filterButton}
                  style={currentCategory === item ? { backgroundColor: 'white' } : {}}>
                  {item}
                </Button>
              )
            })} */}
          </div>
          <Container maxWidth={this.props.maxWidth} style={{ padding: 0 }}>
            <Grid container direction="row" justify="center" alignItems="center" className={classes.photoGallery}>
              {getFeaturedProducts.map(function(item) {
                return (
                  <Grid item xs={6} md={4} lg={3} xl={2} key={item.productID} data-category={item.category}>
                    <Button
                      component={NavLink}
                      to={`/ro/products/${item.productFamily}/${item.productPath}`}
                      className={classes.imageContainer}>
                      <img src={item.productImages[0].src} title={item.productName} alt={item.productName} />
                      <Typography
                        // variant="caption"
                        // component="div"
                        className="imageContainerText">
                        {item.productName}
                      </Typography>
                    </Button>
                  </Grid>
                )
              })}
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center', padding: "32px 16px 16px" }}>
                {/* <Button onClick={() => handleClick(this, '/ro/products')}>Produse</Button> */}
                <Button component={NavLink} variant="contained" color="secondary" to="/ro/products" size="large">
                  Toate Produsele
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      )
    }
  },
)
