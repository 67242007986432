import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '-96px',
    padding: theme.spacing(12, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 3, 12),
    },
    backgroundColor: theme.palette.primary.dark,
    color: 'initial',
  },
  headLine: {
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  headLineBottom: {
    display: 'block',
    width: 30,
    height: 3,
    background: 'black',
  },
  title: {
    margin: theme.spacing(8, 0),
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
}))

export default function Home(props) {
  const theme = useTheme()
  const classes = useStyles()
  const localRef = React.useRef(props.ref)
  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        ref={localRef}
        // style={{ margin: theme.spacing(-16, -8, 0) }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={5}>
            <img
              src="https://arbredesign.ro/images/alin-dumitrescu--arbre-design.jpg"
              alt="Alin DUMITRESCU"
              style={{ width: '100%' }}
            />
          </Grid>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant="h5"
              // style={{ color: 'white' }}
              gutterBottom
              className={classes.headLine}>
              Despre noi
            </Typography>
            <Typography className={classes.headLineBottom} />
            <Typography variant="h3" component="h1" className={classes.title}>
              Bine ai venit în lumea Arbre!
            </Typography>
            <Typography gutterBottom>
              În locul acesta miroase a talaș ondulat, rumeguș proaspăt mărunțit și copilărie. Iar fiecare personaj vine dintr-o poveste.
            </Typography>
            <Typography gutterBottom>
              De la Alice în Wonderland până la Dr. Seuss, din dormitor, prin holuri și sufragerie, și până sus în tavan, ideile prind formă și devin personaje de poveste.
            </Typography>
            <Typography gutterBottom>
              Piesele de mobilier și jucăriile noastre sunt lucrate manual, cu dăruire și răbdare. Folosim lemn zdravăn, răsucit și îndoit în timp, colorat cu vopsele pe bază de apă.
            </Typography>
            <Typography>
              Două mâini pricepute lucrează lemnul lângă un robot agil, care decupează și dă zeci de forme cu frezele sale dințoase și ascuțite. Colecțiile Arbre se adresează Peter Pan-ului din fiecare părinte iubitorilor de frumos și de povești. Și tuturor copiilor pentru care jucăriile sunt parteneri sinceri și durabili de viață.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
