import React from 'react'
import useReactRouter from 'use-react-router'

import { withGoogleSheets } from 'react-db-google-sheets'
import ReactMarkdown from 'react-markdown'

import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { makeStyles } from '@material-ui/core/styles'
import { green, red, grey } from '@material-ui/core/colors'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import Icon from '@mdi/react'
import { mdiCheckCircle, mdiAlertCircle } from '@mdi/js'

import ProgressBar from '../comps/functional/ProgressBar'

import RouteScrollTop from '../comps/RouteScrollTop'
import ProductGallery from '../comps/SwipeableGallery/ProductGallery'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '60vh',
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // color: 'white',
  },
  initialColor: {
    color: 'initial',
  },
  inheritColor: {
    color: 'inherit',
    // color: theme.palette.secondary.main,
  },
  priceFont: {
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 600,
    color: theme.palette.primary.lightest,
  },
  titleFont: {
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    letterSpacing: 1,
  },
  productCategoryTitle: {
    float: 'right',
    verticalAlign: 'top',
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: 'capitalize',
    letterSpacing: 0,
    // color: 'white',
  },
  rating: {
    marginRight: theme.spacing(2),
  },
  shortDesc: {
    height: `${theme.spacing(2.25)}px`,
    lineHeight: `${theme.spacing(2.25)}px`,
    verticalAlign: 'top',
  },
  inStock: {
    // backgroundColor: theme.palette.secondary.dark,
    backgroundColor: green[600],
    color: 'white',
  },
  checkAvail: {
    backgroundColor: red.A700,
    color: 'white',
  },
  inStockCount: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '.8rem',
    fontWeight: 'bold',
  },
  verticalFlex: {
    flexDirection: 'column',
  },
  infoPanels: {
    backgroundColor: theme.palette.primary.main,
  },

  expansionPanelSummaryContent: {
    '&.Mui-expanded': {margin: theme.spacing(2, 0, 1),},
  },

}))

const ProductPage = (props) => {
  const classes = useStyles()
  const { history, location, match } = useReactRouter()
  // console.log("PP-history: ", history);
  const { db } = props

  const [expanded, setExpanded] = React.useState('panel1')
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  // const { loading, data } = props.getData(props.dataSource)
  const loading = false

  if (loading) {
    return (
      <Container maxWidth="xl">
        <ProgressBar size={48} thickness={3} />
      </Container>
    )
  } else {
    const data = db.products
    const product = data.filter(e => e.productPath === match.params.path)[0]

    // props.parentCallback({
    //   '/products': 'Products',
    //   [location.pathname]: product.productName,
    // })

    // console.log(db.productImages.filter(o => o.productID === product.productID))
    // console.log(product.productDesc)

    return (
      <Container maxWidth="xl">
        <RouteScrollTop />
        <Grid container spacing={isWidthUp('md', props.width) ? 6 : 0}>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Grid container spacing={3}>
                {isWidthUp('md', props.width) ? (
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={5} xl={4} style={{ zIndex: 1 }}>
                      <ProductGallery
                        siteLanguage={props.siteLanguage}
                        history={history}
                        location={location}
                        match={match}
                        // images={product.productImages}
                        images={db.productImages.filter(o => o.productID === product.productID)}
                        imagePath={['//arbredesign.ro/images/products',product.productFamilyPath, product.productPath,''].join('/')}
                        autoplay={false}
                        interval={3500}
                        fabSize="medium"
                        fabPosition={32}
                        thumbGridSpacing={2}
                        thumbRowLenght={5}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={7} xl={8}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h3" component="h1" gutterBottom className={classes.titleFont}>
                              {product.productName}{' '}
                              <Chip
                                label={product.productFamily}
                                // variant="outlined"
                                size="small"
                                color="secondary"
                                className={classes.productCategoryTitle}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <Rating
                              readOnly
                              size="small"
                              precision={0.5}
                              value={parseInt(product.productRating)}
                              className={isWidthUp('lg', props.width) && classes.rating}
                            />
                            {isWidthDown('md', props.width) && <br />}
                            <Typography component="span" variant="caption" gutterBottom className={classes.shortDesc}>
                              {product.productShortDesc}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4" component="div" className={classes.priceFont}>
                            <hr style={{ marginTop: 0 }} />
                            {product.productDiscount > 0 ? (
                              <React.Fragment>
                                <span>
                                  {props.siteCurrencyPlacement && props.siteCurrency}{' '}
                                  {(
                                    parseInt(product.productPrice) -
                                    (parseInt(product.productPrice) * parseInt(product.productDiscount)) / 100
                                  ).toFixed(2)}{' '}
                                  {!props.siteCurrencyPlacement && props.siteCurrency}
                                </span>{' '}
                                <span
                                  style={{
                                    fontSize: '80%',
                                    fontWeight: 300,
                                    textDecoration: 'line-through',
                                    color: grey[600],
                                    verticalAlign: 'top',
                                  }}>
                                  {props.siteCurrencyPlacement && props.siteCurrency} {parseInt(product.productPrice).toFixed(2)}{' '}
                                  {!props.siteCurrencyPlacement && props.siteCurrency}
                                </span>
                              </React.Fragment>
                            ) : (
                              <div>
                                {props.siteCurrencyPlacement && props.siteCurrency} {parseInt(product.productPrice).toFixed(2)}{' '}
                                {!props.siteCurrencyPlacement && props.siteCurrency}
                              </div>
                            )}
                            <hr style={{ marginBottom: 0 }} />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Chip
                            size="small"
                            avatar={
                              <Avatar className={classes.inStockCount}>
                                <Icon
                                  path={product.productStockCount > 0 ? mdiCheckCircle : mdiAlertCircle}
                                  size={1}
                                  color="white"
                                />
                              </Avatar>
                            }
                            // label={product.productStockCount > 0 ? 'In Stock' : 'Available Only on Order'}
                            label={
                              product.productStockCount > 0
                                ? `${product.productStockCount} în stock`
                                : 'Disponibil numai la comandă'
                            }
                            className={product.productStockCount > 0 ? classes.inStock : classes.checkAvail}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ExpansionPanel
                            square
                            // expanded={expanded === "panel1"}
                            expanded={true}
                            onChange={handleChange('panel1')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.initialColor}>
                                {/* Description */}
                                Descriere
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productDesc} /></Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <ExpansionPanel
                            square
                            expanded={isWidthUp('lg', props.width) ? true : expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              expandIcon={isWidthDown('md', props.width) && <ExpandMoreIcon />}
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.inheritColor}>
                                {/* Product Information */}
                                Informații produs
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productInfo} /></Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <ExpansionPanel
                            square
                            expanded={isWidthUp('lg', props.width) ? true : expanded === 'panel3'}
                            onChange={handleChange('panel3')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              expandIcon={isWidthDown('md', props.width) && <ExpandMoreIcon />}
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.inheritColor}>
                                {/* Details and Care */}
                                Detalii
                              </Typography>
                            </ExpansionPanelSummary>
                            {product.productDetails && <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productDetails} /></Box>
                            </ExpansionPanelDetails>}
                            {product.productCare && <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productCare} /></Box>
                            </ExpansionPanelDetails>}
                          </ExpansionPanel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={5} xl={4} style={{ zIndex: 1 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h3" component="h1" gutterBottom className={classes.titleFont}>
                              {product.productName}{' '}
                              <Chip
                                label={product.productFamily}
                                // variant="outlined"
                                size="small"
                                color="secondary"
                                className={classes.productCategoryTitle}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <Rating
                              readOnly
                              size="small"
                              precision={0.5}
                              value={parseInt(product.productRating)}
                              className={isWidthUp('lg', props.width) ? classes.rating : ''}
                            />
                            {isWidthDown('md', props.width) && <br />}
                            <Typography component="span" variant="caption" gutterBottom className={classes.shortDesc}>
                              {product.productShortDesc}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4" component="div" className={classes.priceFont}>
                            <hr style={{ marginTop: 0 }} />
                            {product.productDiscount > 0 ? (
                              <React.Fragment>
                                <span>
                                  {props.siteCurrencyPlacement && props.siteCurrency} {parseInt(product.productPrice).toFixed(2)}{' '}
                                  {!props.siteCurrencyPlacement && props.siteCurrency}
                                </span>{' '}
                                <span
                                  style={{
                                    fontSize: '80%',
                                    fontWeight: 300,
                                    textDecoration: 'line-through',
                                    color: grey[600],
                                    verticalAlign: 'top',
                                  }}>
                                  {props.siteCurrencyPlacement && props.siteCurrency} {parseInt(product.productPrice).toFixed(2)}{' '}
                                  {!props.siteCurrencyPlacement && props.siteCurrency}
                                </span>
                              </React.Fragment>
                            ) : (
                              <div>
                                {props.siteCurrencyPlacement && props.siteCurrency} {parseInt(product.productPrice).toFixed(2)}{' '}
                                {!props.siteCurrencyPlacement && props.siteCurrency}
                              </div>
                            )}
                            <hr style={{ marginBottom: 0 }} />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Chip
                            size="small"
                            avatar={
                              <Avatar className={classes.inStockCount}>
                                <Icon
                                  path={product.productStockCount > 0 ? mdiCheckCircle : mdiAlertCircle}
                                  size={1}
                                  color="white"
                                />
                              </Avatar>
                            }
                            label={
                              product.productStockCount > 0
                                ? `${product.productStockCount} în stock`
                                : 'Disponibil numai la comandă'
                            }
                            className={product.productStockCount > 0 ? classes.inStock : classes.checkAvail}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductGallery
                            siteLanguage={props.siteLanguage}
                            history={history}
                            location={location}
                            match={match}
                            images={db.productImages.filter(o => o.productID === product.productID)}
                            imagePath={['//arbredesign.ro/images/products',product.productFamilyPath, product.productPath,''].join('/')}
                            autoplay={false}
                            interval={3500}
                            fabSize="medium"
                            fabPosition={32}
                            thumbGridSpacing={2}
                            thumbRowLenght={4}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={7} xl={8}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ExpansionPanel
                            square
                            // expanded={expanded === "panel1"}
                            expanded={true}
                            onChange={handleChange('panel1')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.initialColor}>
                                {/* Description */}
                                Descriere
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productDesc} /></Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <ExpansionPanel
                            square
                            expanded={isWidthUp('lg', props.width) ? true : expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              expandIcon={isWidthDown('md', props.width) && <ExpandMoreIcon />}
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.inheritColor}>
                                {/* Product Information */}
                                Informații produs
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productInfo} /></Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <ExpansionPanel
                            square
                            expanded={isWidthUp('lg', props.width) ? true : expanded === 'panel3'}
                            onChange={handleChange('panel3')}
                            className={classes.infoPanels}>
                            <ExpansionPanelSummary
                              expandIcon={isWidthDown('md', props.width) && <ExpandMoreIcon />}
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                              classes={{content: classes.expansionPanelSummaryContent}}
                            >
                              <Typography variant="h6" component="h2" className={classes.inheritColor}>
                                {/* Details and Care */}
                                Detalii
                              </Typography>
                            </ExpansionPanelSummary>
                            {product.productDetails && <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productDetails} /></Box>
                            </ExpansionPanelDetails>}
                            {product.productCare && <ExpansionPanelDetails>
                              <Box><ReactMarkdown source={product.productCare} /></Box>
                            </ExpansionPanelDetails>}
                          </ExpansionPanel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withGoogleSheets(['config', 'products', 'productImages'])(withWidth()(ProductPage))
