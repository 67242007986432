import { createMuiTheme } from '@material-ui/core/styles'
import {
  amber,
  grey,
  teal,
  // pink,
} from '@material-ui/core/colors'
// import { dark } from '@material-ui/core/styles/createPalette'

const mainColor = 'white'
const mainBkColor = '#c8c6a0'
const mainBkColorDark = '#6e6d5b'

const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: { main: mainBkColor },
    secondary: { main: amber[500] },
  },
})

const MainTheme = {
  ...theme,
  overrides: {
    MuiButton: {
      root: {
        color: mainColor,
      },
    },
    MuiAppBar: { colorPrimary: { color: mainColor } },
    // MuiDrawer: {
    //   paper: {
    //     // backgroundColor: grey[800]
    //   }
    // },
    // MuiListItem: {
    //   root: {
    //     // backgroundColor: grey[800],
    //     // color: grey[50]
    //   }
    // },
    MuiExpansionPanelSummary: {
      root: {
        color: mainBkColorDark,
        '&$expanded': {
          color: grey[900],
        },
      },
    },
  },
}

export { MainTheme }
