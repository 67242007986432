import Home from '../layouts/Home'
import ProductList from '../layouts/ProductList'
import ProductPage from '../layouts/ProductPage'

const componentList = {
  // LAYOUT
  Home: Home,
  ProductList: ProductList,
  ProductPage: ProductPage,
  // CONTENT
}

export default componentList
