import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

const styles = theme => ({
  root: {
    border: 0,
    background: 'none',
    padding: 0,
  },
  dot: {
    cursor: 'pointer',
    opacity: 0.35,
    '&:hover': {
      opacity: 0.75,
    },
    backgroundColor: 'grey',
  },
  active: {
    backgroundColor: 'grey',
    opacity: 1,
  },
  dotSize: {
    width: '100%',
    height: '100%',
  },
})

class PaginationDot extends React.Component {
  handleClick = event => {
    this.props.onClick(event, this.props.index)
  }

  render() {
    const { classes, active, image, dotSize } = this.props

    return (
      <Card
        type="button"
        className={active ? classes.active : classes.dot}
        style={{
          width: dotSize,
          height: dotSize,
          borderRadius: 0,
        }}
        onClick={this.handleClick}
        elevation={0}>
        <CardMedia image={image} className={classes.dotSize} />
      </Card>
    )
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(PaginationDot)
