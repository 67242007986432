import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import PaginationDot from './PaginationDot'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
})

class Pagination extends React.Component {
  state = {
    spacing: 2,
    dimensions: null,
  }

  componentDidMount() {
    this.setState({
      dimensions: this.container.offsetWidth,
    })
  }

  handleClick = (event, index) => {
    this.props.onChangeIndex(index)
  }

  returnDots() {
    const { index, images, imagePath } = this.props
    const { dimensions } = this.state

    const rowLenght = !this.props.rowLenght ? 4 : this.props.rowLenght
    const imagesCounter = images.length > rowLenght ? rowLenght : images.length
    let dotSize = dimensions && Math.floor(dimensions / imagesCounter) - this.props.gridSpacing * 8 - 0.25

    // console.log(dimensions);

    return (
      <React.Fragment>
        {images.length > 1 &&
          images.map((image, i) => {
            return (
              <Grid key={i} item>
                <PaginationDot
                  key={i}
                  image={imagePath + image.src}
                  dotSize={dotSize}
                  index={i}
                  active={i === this.props.index}
                  onClick={this.handleClick}
                />
              </Grid>
            )
          })}
      </React.Fragment>
    )
  }

  render() {
    const { classes, index, images } = this.props
    const { spacing, dimensions } = this.state

    return (
      <Grid container spacing={spacing} className={classes.root} ref={el => (this.container = el)}>
        {dimensions && this.returnDots()}
      </Grid>
    )
  }
}

Pagination.propTypes = {
  // dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
}

export default withStyles(styles)(Pagination)
