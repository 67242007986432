import React from 'react'
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { MainTheme as theme } from './themes/MainTheme'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
// import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import config from './config/core'

import { useFetch } from './hooks/fetchJSON'
import componentList from './core/ComponentIterator'

// import Breadcrumbs from './comps/RouterBreadcrumbs'
// import ProductList from './layouts/ProductList'
// import ProductPage from './layouts/ProductPage'
import ProgressBar from './comps/functional/ProgressBar'
import MainMenu from './comps/MainMenu'

import Footer from './layouts/page/Footer'

import ShowBreakpoints from './tests/ShowBreakpoints'

import WebFont from 'webfontloader'
WebFont.load({
  google: {
    families: [
      'Quicksand:300,400,500,600,700:latin,latin-ext',
      'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i:latin,latin-ext',
      'sans-serif',
    ],
  },
})

// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
// https://codesandbox.io/s/use-scroll-position-8nfin

const useStyles = makeStyles({
  'html>body': {
    backgroundColor: theme.palette.primary.main,
  },
  main: {
    minHeight: '100vh',
    padding: theme.spacing(16, 0, 12),
    backgroundColor: theme.palette.primary.main,
    // background: `linear-gradient(0deg, rgba(110, 109, 91, 1) 0, rgba(${
    //   theme.palette.primary.main
    // }, 0) 256px) no-repeat`,
    // color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(12, 0),
    },
  },
})

const App = (props) => {
  const classes = useStyles()

  const { db } = props

  const [siteData, setSiteData] = React.useState([])

  const { siteName, siteLanguage } = config
  const siteCurrency = siteLanguage === 'ro' ? 'Lei' : '&euro;'
  const siteCurrencyPlacement = siteLanguage === 'ro' ? false : true
  const { loading, data } = useFetch('mainRequest')

  React.useEffect(() => {
    setSiteData(data)
  }, [data])

  if (loading) {
    return (
      <Container maxWidth="xl" style={isWidthDown('sm', props.width) ? { paddingTop: 96 } : { paddingTop: 128 }}>
        <ProgressBar size={48} thickness={3} />
      </Container>
    )
  } else {
    const routeData = siteData[0].routes
    const productData = siteData[1].products

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} /> */}
          <MainMenu
            position="top"
            siteStructure={routeData}
            siteName={siteName}
            siteLanguage={siteLanguage}
            showSiteName={false}
          />
          <main className={classes.main}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to={'/' + siteLanguage + '/'} />} />
              {routeData.map((route, index) => {
                const ComponentIterator = componentList[route.component]
                const routePath = '/' + siteLanguage + '/' + route.path
                if (route.route)
                  return (
                    <Route {...route.routeProps} path={routePath} key={index}>
                      <ComponentIterator
                        getData={useFetch}
                        dataSource={route.dataSource}
                        setData={productData}
                        // getProps={route.props}
                        {...route.props}
                        // parentCallback={getNameMap}
                        siteLanguage={siteLanguage}
                        siteCurrency={siteCurrency}
                        siteCurrencyPlacement={siteCurrencyPlacement}
                        pageName={route.name}
                      />
                    </Route>
                  )
              })}
            </Switch>
          </main>
          <ShowBreakpoints theme={theme} show={false} />
          <Footer siteName={siteName} siteLanguage={siteLanguage} showSiteName={false} />
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}

export default withGoogleSheets()(withWidth()(App))
