import React, { useRef } from 'react'

import { useTheme } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Slider from '../comps/Slider'
import Mozaic from '../comps/Mozaic'
import About from '../comps/About'

// const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop) // General scroll to element function
const sliderContent = [
  { caption: '', image: '/images/slider/Arbre-Cover-1920.jpg' },
  { caption: '', image: '/images/slider/Arbre-Wonderland-1920.jpg' },
  { caption: '', image: '/images/slider/Arbre-UnderTheSea-1920.jpg' },
]

export default function Home(props) {
  const theme = useTheme()
  // const about = useRef(null)
  // const top = useRef(null)
  // const executeScroll = () => scrollToRef(about)
  // const executeScrollTop = () => scrollToRef(top)

  return (
    <React.Fragment>
      {/* <div ref={top} /> */}
      <Slider content={sliderContent} scale={1.2} />
      {/* <Container
        maxWidth="xl"
        // style={{ margin: theme.spacing(-16, -8, 0) }}
      > */}
      {/* <Typography variant="h1" style={{ color: 'white' }}>
          {props.pageName}
        </Typography> */}
      {/* <button onClick={executeScroll}>about</button> */}
      {/* </Container> */}
      <Mozaic
        content={props.setData}
        maxWidth={false}
        // style={{ marginTop: theme.spacing(-8) }}
      />
      <div
      // ref={about}
      >
        <About />
      </div>
      {/* <button onClick={executeScrollTop}>top</button> */}{' '}
    </React.Fragment>
  )
}
