import React from 'react'

import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
})

export default withWidth()(
  withStyles(styles)(
    class ContactForm extends React.Component {
      constructor(props) {
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.state = {
          formStatus: '',
        }
      }

      submitForm(ev) {
        ev.preventDefault()
        const form = ev.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.formStatus === 200) {
            form.reset()
            this.setState({ formStatus: 'SUCCESS' })
          } else {
            this.setState({ formStatus: 'ERROR' })
          }
        }
        xhr.send(data)
      }

      render() {
        const { classes } = this.props
        return (
          <Paper className={classes.root}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
              Contactează-ne!
            </Typography>
            <form onSubmit={this.submitForm} action="https://formspree.io/xjvvwnyy" method="POST">
              <input name="Tip" type="hidden" value="Formular de contact ARBRE WebSite" />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="name"
                    name="Name"
                    type="text"
                    label="Nume și prenume"
                    margin="dense"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="email"
                    name="Email"
                    type="email"
                    label="Adresă email"
                    margin="dense"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="message"
                    name="Mesaj"
                    label="Mesaj"
                    multiline
                    rows="4"
                    margin="dense"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.state.formStatus === 'SUCCESS' ? (
                    <p>Thanks!</p>
                  ) : (
                    <Button
                      id="submit_button"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={isWidthDown('md', this.props.width)}
                      type="submit"
                      style={isWidthDown('md', this.props.width) ? { padding: 16 } : {}}>
                      Trimite mesajul
                    </Button>
                  )}
                  {/* {this.state.formStatus === 'ERROR' && <p>Ooops! There was an error.</p>} */}
                </Grid>
              </Grid>
            </form>
          </Paper>
        )
      }
    },
  ),
)
