import React from 'react'
// import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { withGoogleSheets } from 'react-db-google-sheets'

import clsx from 'clsx'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { makeStyles } from '@material-ui/core/styles'
import { green, red, grey } from '@material-ui/core/colors'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
// import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
// import CardContent from '@material-ui/core/CardContent'
// import CardActions from '@material-ui/core/CardActions'
// import IconButton from "@material-ui/core/IconButton";

import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

// import InquieryIcon from "@material-ui/icons/Message";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";

import Icon from '@mdi/react'
import { mdiCheckCircle, mdiAlertCircle } from '@mdi/js'

import RouteScrollTop from '../comps/RouteScrollTop'
import ProgressBar from '../comps/functional/ProgressBar'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
    },
  },
  mediaSize: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 320,
    },
    [theme.breakpoints.only('sm')]: {
      height: 448,
    },
    [theme.breakpoints.only('md')]: {
      height: 384,
    },
    [theme.breakpoints.only('lg')]: {
      height: 320,
    },
    [theme.breakpoints.up('xl')]: {
      height: 448,
    },
  },
  productCategoryTitle: {
    margin: theme.spacing(2, 0),
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    textTransform: 'capitalize',
    // color: 'white',
  },
  productCard: {
    cursor: 'pointer',
    backgroundColor: grey[900],
    borderRadius: 0,
  },
  productCardHeader: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: theme.spacing(8),
    // background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
    background: 'linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%)',
  },
  productCardHeaderContent: {
    width: '50%',
  },
  productCardHeaderTitle: {
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontSize: '1.4rem',
    fontWeight: 500,
    color: 'white',
  },
  productCardSubHeader: {
    display: 'block',
    width: '100%',
    color: grey[500],
  },
  pricePosition: {
    marginTop: theme.spacing(0.125),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  priceFont: {
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    textAlign: 'right',
    // color: theme.palette.primary.dark,
    color: 'white',
  },
  stockStatus: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  inStock: {
    backgroundColor: green[600],
    color: 'white',
  },
  checkAvail: {
    backgroundColor: red.A700,
    color: 'white',
  },
  inStockCount: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '.8rem',
    fontWeight: 'bold',
  },
  actionIcons: {
    marginLeft: 'auto',
  },
}))

const ProductList = (props) => {
  const classes = useStyles()
  // const [productListData, setProductListData] = React.useState()
  // const { loading, data } = props.getData(props.dataSource)
  // // console.log("PLp: ", props);
  const { db } = props

  console.log(
    db.products,
    // db.config,
    db.productImages.find(o => o.featured === 'TRUE' && o.productID === '2'),
    // props.setData
  )

  // React.useEffect(() => {
  //   setProductListData(data)
  // }, [data])

  let history = useHistory()
  const handleClick = (event, path) => {
    // console.log("clicked ", path);
    history.push('products/' + path)
  }

  const loading = false

  if (loading) {
    return (
      <Container maxWidth="xl">
        <ProgressBar size={48} thickness={3} />
      </Container>
    )
  } else {
    let groupedBy = _.mapValues(_.groupBy(db.products, 'productFamily'), productList =>
      productList.map(product => product),
    )
    const productListData = Object.entries(groupedBy)

    return (
      <Container maxWidth="xl" className={classes.root}>
        <RouteScrollTop />

        <Grid container spacing={isWidthUp('md', props.width) ? 6 : 2}>
          {productListData.map((family, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h2" className={classes.productCategoryTitle}>
                    {family[0]}
                  </Typography>
                </Grid>
                {family[1].map((product, index) => {
                  // const imagePath = db.config.find(o => o.key === 'imageFolder');
                  const imagePath = ['//arbredesign.ro/images/products',product.productFamilyPath, product.productPath,''].join('/');
                  return (
                    <Grid key={index} item xs={12} md={6} lg={4}>
                      <Card
                        type="button"
                        onClick={() => handleClick(this, product.productFamilyPath + '/' + product.productPath)}
                        elevation={1}
                        className={classes.productCard}>
                        <CardMedia image={`${imagePath}${db.productImages.find(o => o.featured === 'TRUE' && o.productID === product.productID).src}`} className={classes.mediaSize}>
                          <Chip
                            size="small"
                            avatar={
                              <Avatar className={classes.inStockCount}>
                                <Icon
                                  path={product.productStockCount > 0 ? mdiCheckCircle : mdiAlertCircle}
                                  size={1}
                                  color="white"
                                />
                              </Avatar>
                            }
                            // label={product.productStockCount > 0 ? 'In Stock' : 'Available Only on Order'}
                            label={
                              product.productStockCount > 0
                                ? `${product.productStockCount} în stock`
                                : 'Disponibil numai la comandă'
                            }
                            className={
                              product.productStockCount > 0
                                ? clsx(classes.inStock, classes.stockStatus)
                                : clsx(classes.checkAvail, classes.stockStatus)
                            }
                          />
                          <CardHeader
                            // avatar={
                            //   <Avatar
                            //     aria-label="recipe"
                            //     className={
                            //       product.productStockCount > 0
                            //         ? classes.inStock
                            //         : classes.checkAvail
                            //     }
                            //   >
                            //     {product.productStockCount}
                            //   </Avatar>
                            // }
                            title={
                              <Typography variant="h5" component="div" className={classes.productCardHeaderTitle}>
                                {product.productName}
                              </Typography>
                            }
                            subheader={
                              <React.Fragment>
                                <Typography variant="caption" noWrap className={classes.productCardSubHeader}>
                                  {product.productShortDesc}
                                </Typography>
                              </React.Fragment>
                            }
                            action={
                              props.showPrice && (
                                <Typography
                                  variant="h5"
                                  component="div"
                                  className={clsx(classes.priceFont, classes.productCardHeaderTitle)}>
                                  {product.productDiscount > 0 ? (
                                    <React.Fragment>
                                      <div>
                                        {props.siteCurrencyPlacement && props.siteCurrency}{' '}
                                        {(
                                          product.productPrice -
                                          (product.productPrice * product.productDiscount) / 100
                                        ).toFixed(2)}{' '}
                                        {!props.siteCurrencyPlacement && props.siteCurrency}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '70%',
                                          fontWeight: 300,
                                          textDecoration: 'line-through',
                                          color: grey[600],
                                        }}>
                                        {props.siteCurrencyPlacement && props.siteCurrency}{' '}
                                        {product.productPrice.toFixed(2)}{' '}
                                        {!props.siteCurrencyPlacement && props.siteCurrency}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <div>
                                      {props.siteCurrencyPlacement && props.siteCurrency}{' '}
                                      {product.productPrice.toFixed(2)}{' '}
                                      {!props.siteCurrencyPlacement && props.siteCurrency}
                                    </div>
                                  )}
                                </Typography>
                              )
                            }
                            classes={{
                              root: classes.productCardHeader,
                              content: classes.productCardHeaderContent,
                              // title: classes.productCardHeaderTitle,
                              // subheader: classes.productCardSubHeader,
                              action: classes.pricePosition,
                            }}
                          />
                        </CardMedia>

                        {/* <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {product.productShortDesc}
                    </Typography>
                  </CardContent> */}

                        {/* <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" disabled>
                      <InquieryIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      disabled
                      className={classes.actionIcons}
                    >
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share" disabled>
                      <ShareIcon />
                    </IconButton>
                  </CardActions> */}
                      </Card>
                    </Grid>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Grid>
      </Container>
    )
    //   }
  }
}

export default withGoogleSheets(['config', 'products', 'productImages'])(ProductList)
