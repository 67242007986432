import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay, bindKeyboard } from 'react-swipeable-views-utils'

import clsx from 'clsx'
import { withStyles, withTheme } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Fab from '@material-ui/core/Fab'
import BackIcon from '@material-ui/icons/ArrowBack'
import Icon from '@mdi/react'
import { mdiPause, mdiPlay } from '@mdi/js'

import Pagination from './Pagination'
// import ProgressBar from "./ProgressBar";

const AutoPlaySwipeableViews = bindKeyboard(autoPlay(SwipeableViews))

const styles = theme => ({
  root: {
    position: 'relative',
  },
  slideContainer: {
    // borderRadius: theme.shape.borderRadius + 1,
  },
  slide: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: 'grey',
  },
  image: {
    width: '100%',
    // height: '100%',
    objectFit: 'cover',
  },
  imageTitle: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%)',
    color: 'white',
    fontFamily: 'Quicksand, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '1.16rem',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  autoPlay: {
    position: 'absolute',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  progressPosition: {
    position: 'absolute',
  },
  progressCircle: {
    stroke: 'rgba(0,0,0,.5)',
    fill: 'transparent',
  },
  backButton: {
    position: 'absolute',
    top: 16,
    left: 16,
    padding: 0,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.7)',
    },
  },
  backButtonIcon: {
    // color: theme.palette.secondary.main
    color: 'white',
  },
})

export default withTheme(
  withStyles(styles)(
    class ProductGallery extends React.Component {
      state = {
        index: 0,
        autoplay: this.props.autoplay,
        dimensions: 0,
      }

      componentDidMount() {
        this.setState({
          dimensions: this.container.offsetWidth,
        })
      }

      handleChangeIndex = index => {
        this.setState({
          index,
        })
      }

      handleAutoPlay = () => {
        this.setState({
          autoplay: !this.state.autoplay,
        })
      }

      handleGoBack = () => {
        this.props.history.push('/' + this.props.siteLanguage + '/products')
      }

      returnMainImage(imagePath, src, index, name) {
        const { classes } = this.props
        return <img src={imagePath + src} width="100%" className={classes.image} alt={name} />
      }

      render() {
        const { index, autoplay, dimensions } = this.state
        const { theme, classes, images, imagePath, interval, fabSize, fabPosition, thumbGridSpacing, thumbRowLenght } = this.props

        // default progress size
        let progressSize = 48,
          progressBarThickness = 4
        switch (fabSize) {
          case 'small':
            progressSize = 40
            progressBarThickness = 3
            break
          case 'medium':
            progressSize = 48
            break
          case 'large':
            progressSize = 56
            break
          default:
            progressSize = 56
            progressBarThickness = 4
        }
        const progressBarRadius = (progressSize - progressBarThickness) / 2
        // adjust imageTitle accordingly
        const imageStyles = {
          height: dimensions,
        }
        const imageTitleStyles = {
          lineHeight: `${progressSize + theme.spacing(1)}px`,
          padding: theme.spacing(1.5, (fabPosition + progressSize) / 8, 2.5, fabPosition / 8),
        }

        // console.log(this.props)

        return (
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} md={12} style={{ position: 'relative' }} ref={el => (this.container = el)}>
              <AutoPlaySwipeableViews
                autoplay={autoplay}
                interval={interval}
                index={index}
                onChangeIndex={this.handleChangeIndex}
                className={classes.slideContainer}
                springConfig={{
                  duration: '0.5s',
                  easeFunction: 'ease-in-out',
                  delay: '0s',
                }}>
                {images.map((image, index) => {
                  return (
                    <Paper key={index} className={classes.slide} style={imageStyles} square>
                      <Fab
                        color="secondary"
                        size="large"
                        onClick={this.handleGoBack}
                        className={clsx(classes.backButton)}>
                        <BackIcon className={classes.backButtonIcon} />
                      </Fab>

                      <Typography component="div" className={classes.imageTitle} style={imageTitleStyles}>
                        {image.name}
                      </Typography>
                      {dimensions && this.returnMainImage(imagePath, image.src, index, image.name)}
                    </Paper>
                  )
                })}
              </AutoPlaySwipeableViews>
              {autoplay && (
                // ## ALTERNATIVE ProgressBar MUI component
                // <ProgressBar
                //   position={classes.progressPosition}
                //   time={interval}
                //   step={10}
                // />
                <svg
                  height={progressSize}
                  width={progressSize}
                  className="circularProgressBar"
                  style={{
                    width: progressSize,
                    height: progressSize,
                    right: fabPosition,
                    bottom: fabPosition,
                    strokeDasharray: Math.PI * 2 * progressBarRadius,
                    strokeDashoffset: Math.PI * 2 * progressBarRadius,
                    animationDuration: `${interval / 1000}s`,
                  }}>
                  <circle
                    cx={progressSize / 2}
                    cy={progressSize / 2}
                    r={progressBarRadius}
                    strokeWidth={progressBarThickness}
                    className={classes.progressCircle}
                  />
                </svg>
              )}
              {images.length > 1 && (
                <Fab
                  size={fabSize}
                  onClick={this.handleAutoPlay}
                  className={classes.autoPlay}
                  style={{
                    right: fabPosition,
                    bottom: fabPosition,
                  }}>
                  <Icon path={autoplay ? mdiPause : mdiPlay} size={1} color="white" />
                </Fab>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Pagination
                index={index}
                onChangeIndex={this.handleChangeIndex}
                images={images}
                imagePath={imagePath}
                rowLenght={thumbRowLenght}
                gridSpacing={thumbGridSpacing}
              />
            </Grid>
          </Grid>
        )
      }
    },
  ),
)
