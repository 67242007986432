import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import withWidth from '@material-ui/core/withWidth'

class ShowBreakpoints extends React.Component {
  render() {
    const { theme, width, show } = this.props

    if (show) {
      return (
        <Paper style={{ margin: theme.spacing(8, 4, 4), padding: theme.spacing(1, 2), textAlign: 'center' }}>
          breakpoint: <strong style={{ textTransform: 'uppercase' }}>{width}</strong>
        </Paper>
      )
    } else {
      return null
    }
  }
}

ShowBreakpoints.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

export default withWidth()(ShowBreakpoints)
