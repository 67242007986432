import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { withStyles } from '@material-ui/core/styles'

const ColorCircularProgress = withStyles({
  root: {
    color: 'white',
  },
})(CircularProgress)

export default function ProgressBar(props) {
  return <ColorCircularProgress {...props} />
}
