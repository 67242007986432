import { useState, useEffect } from 'react'

export const useFetch = url => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  async function fetchData() {
    const response = await fetch('/data/' + url + '.json')
    const json = await response.json()
    setData(json['data'])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [url])

  return { loading, data }
}
